import {
  ApolloClient
} from 'apollo-client'
import {
  FILE_NAME,
  HTTP_CODES,

} from "../config/API_DATA";
import {
  InMemoryCache
} from 'apollo-cache-inmemory';
import {
  setContext
} from 'apollo-link-context';
import {
  split
} from 'apollo-link'
import {
  getMainDefinition
} from 'apollo-utilities'
import {
  handleJWT,
} from "./../util/util"
import {
  i18n
} from "./../plugins/i18n.js";
import {
  createHttpLink
} from 'apollo-link-http';
import Router, {
  navigate
} from '../router';
import {
  PATHNAME
} from '../util/constants';
import Logger from '../services/logger';
import SERVICE_URL, {
  LOG_TYPE
} from '../config/API_DATA';
import BaseConfiguration from '../config/base';


export function handleApolloJWT(pError) {
  if (pError && pError.extensions && pError.extensions.code == HTTP_CODES.SUBSCRIPTION_FAILED && pError.message.includes(HTTP_CODES.ERROR_JWT.toUpperCase())) {
    let jwtErr = {
      "status": HTTP_CODES.ERROR_STR,
      "error": [{
        "code": HTTP_CODES.ERROR_JWT_01,
        "value": i18n.tc('loginPg.jwtExpiredValue')
      }]
    }
    handleJWT(jwtErr);
  }
}

export default class Apollo {
  static client = null;
  static createApolloClient() {
    console.log('apollo log created start');
    const cache = new InMemoryCache({
      resultCaching: false,
      addTypename: false,
      freezeResults: false
    })
    let url = BaseConfiguration.qlUrl;
    let key = BaseConfiguration.qlKey;
    if (url, key) {
      const httpLink = createHttpLink({
        // You should use an absolute URL here
        uri: url
      })
      const authLink = setContext((_, {
        headers
      }) => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            "x-hasura-admin-secret": key
          },
        };
      })
      Apollo.client = new ApolloClient({
        url: url,
        link: split(
          // split based on operation type
          ({
            query
          }) => {
            const definition = getMainDefinition(query)
            return definition.kind ? true : false
          },
          authLink.concat(httpLink),
        ),
        cache: cache,
        defaultOptions: {
          query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
          }
        }
      })
      console.log('apollo log created');
    }
  }
  static async makeApolloQueryRequest(pUrl, pRequestBody) {
    let returnValue = null;

    try {
      //to check  network connection
      if (navigator.onLine) {
        if (!Apollo.client && BaseConfiguration.qlUrl && BaseConfiguration.qlKey) {
          Apollo.createApolloClient()
        } else if (!Apollo.client) {
          throw HTTP_CODES.ERROR_NET;
        }
        console.log("makeApolloQueryRequest")
        returnValue = await Apollo.client.query({
            query: pRequestBody
          })
          .then((response) => {
            console.log(response)
            return response;
          })
          .catch(error => {
            console.log(error)
            if (pUrl != SERVICE_URL.LOGGER_URL) {
              // Router.push("pError");
              let err = {
                service: pUrl,
                error: error.toString()
              }
              let body = Logger.buildRequest(
                FILE_NAME.HTTPOST,
                "error in making post request",
                JSON.stringify(err),
                "makeApolloQueryRequest",
                LOG_TYPE.ERROR
              );
              Logger.getResponse(body);
            }
          }).finally(() => {
            return returnValue;
          });
      } else {
        throw HTTP_CODES.ERROR_NET;
      }
    } catch (err) {
      // Router.push("pError");
      if (err == HTTP_CODES.ERROR_NET || !navigator.onLine) {
        if (Router.currentRoute.path != PATHNAME.PAGE_404) {
          navigate(PATHNAME.PAGE_404)
        }
      } else {
        let error = {
          service: pUrl,
          error: err.toString()
        }
        let body = Logger.buildRequest(
          FILE_NAME.APLLO_CLIENT,
          "apllo call failed failed",
          JSON.stringify(error),
          "makeApolloQueryRequest",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    }
    return returnValue;
  }

  static async makeApolloMutationRequest(pUrl, pRequestBody) {
    let returnValue = null;

    try {
      //to check  network connection
      if (navigator.onLine) {
        console.log("makeApolloMutationRequest")
        if (!Apollo.client && BaseConfiguration.qlUrl && BaseConfiguration.qlKey) {
          Apollo.createApolloClient()
        } else if (!Apollo.client) {
          throw HTTP_CODES.ERROR_NET;
        }
        returnValue = await Apollo.client.mutate({
            mutation: pRequestBody
          })
          .then((response) => {
            console.log(response)
            return response;
          })
          .catch(error => {
            console.log(error)
            if (pUrl != SERVICE_URL.LOGGER_URL) {
              // Router.push("pError");
              let err = {
                service: pUrl,
                error: error.toString()
              }
              let body = Logger.buildRequest(
                FILE_NAME.HTTPOST,
                "error in making post request",
                JSON.stringify(err),
                "makeApolloMutationRequest",
                LOG_TYPE.ERROR
              );
              Logger.getResponse(body);
            }
          });
      } else {
        throw HTTP_CODES.ERROR_NET;
      }
    } catch (err) {
      // Router.push("pError");
      if (err == HTTP_CODES.ERROR_NET || !navigator.onLine) {
        if (Router.currentRoute.path != PATHNAME.PAGE_404) {
          navigate(PATHNAME.PAGE_404)
        }
      } else {
        let error = {
          service: pUrl,
          error: err.toString()
        }
        let body = Logger.buildRequest(
          FILE_NAME.APLLO_CLIENT,
          "apllo call failed",
          JSON.stringify(error),
          "makeApolloMutationRequest",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    }
    return returnValue;
  }

}
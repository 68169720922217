import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiCreateCategory {
    static buildRequest(value) {
        let returnValue = null;
        try {
            if (!value) throw new Error('Error value not found')
            let query = gql `mutation insert_diy_project {
                insert_diy_project_category(objects: {
                    categoryname: "${value.categoryname}",
                    groupid: ${value.groupid}
                }, on_conflict: {
                    constraint: category_pkey,
                    update_columns: categoryid
                }) {
                    returning {
                        categoryid,
                        categoryname
                    }
                }
            }
              `;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_CATEGORY, "error in building query for CreateDiyPostDetails api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.CREATE_DIY_CATEGORY, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_CATEGORY, "error in getting Response from CreateDiyPostDetails api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}
import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import gql from "graphql-tag";
import Apollo from "../../graphql/ApolloClient";

export default class ApiDIYGetDiyGroupLs {
    static buildRequest() {
        let returnValue = null;
        try {
            let query = gql`{
                diy_project_project_group {
                  groupid
                  description
                }
              }
              `;

            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_DIY_GROUP, "error in building body for GetAllGroup api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloQueryRequest(SERVICE_URL.API_GET_DIY_GROUP, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_DIY_GROUP, "error in getting Response from GetAllGroup api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}
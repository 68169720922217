import SERVICE_URL from "../config/API_DATA";
import HttpPost from "../http/httppost.js";
import Logger from "./logger.js";
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";
export default class UserRoles {
    static buildRequest(pRoleType) {
        let returnValue = null;
        try {

          
            let body ={

                roleType : pRoleType
            };


            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(
                FILE_NAME.API_USER_ROLES,
                "error in building body for  User Roles api",
                err.toString(),
                "buildRequest",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(
                SERVICE_URL.GET_USER_ROLES,
                pReqOptions
            );
        } catch (err) {
            let body = Logger.buildRequest(
                FILE_NAME.API_USER_ROLES,
                "error in getting Response from  UserRoles api ",
                err.toString(),
                "getResponse",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return returnValue;
    }
}
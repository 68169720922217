import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import gql from "graphql-tag";
import Apollo from "../../graphql/ApolloClient";

export default class ApiDIYGetDiyCategory {
    static buildRequest(groupid) {
        let returnValue = null;
        try {
            let query = gql`{
                diy_project_category(where: {groupid: {_eq: ${groupid}}}) {
                  categoryid
                  categoryname
                }
              }
              `;

            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_DIY_CATEGORY, "error in building body for GetAllUser api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloQueryRequest(SERVICE_URL.GET_ALL_DIY_CATEGORY, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_DIY_CATEGORY, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}
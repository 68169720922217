<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :label="label"
    :vid="vid"
    v-slot="{ errors }"
  >
    <CRow>
      
      <CCol col="12">
        <label v-if="label">{{ label }}</label>
        <div class="con-multi">
          
          <multiselect
            :disabled="isDisable"
            v-model="selValueModel"
            :track-by="ctrlCode"
            :label="ctrlName"
            :taggable="isTaggable"
            placeholder="Select"
            :options="options"
            :searchable="isSearchable"
            :multiple="isMultiple"
            :allow-empty="isAllowEmpty"
            :loading="isInValidOptions()"
            deselectLabel
            deselectGroupLabel
            selectLabel
            selectedLabel
            @input="onChange"
            @tag="addTag"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <div v-if="ctrlName1 && option[ctrlName1]">
                {{ option[ctrlName] + "(" + option[ctrlName1] + ")" }}
              </div>
              <div v-else>
                {{ option[ctrlName] }}
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="d-flex">
                <div v-if="ctrlName1 && props.option[ctrlName1]">
                  <span>{{
                    props.option[ctrlName] +
                    " (" +
                    props.option[ctrlName1] +
                    ") "
                  }}</span>
                </div>
                <div v-else-if="ctrlName">
                  <span>{{ props.option[ctrlName] }}</span>
                </div>
                <div v-else>
                  
                  <span>{{ props.option}}</span>
                </div>
                <strong class="right ml-auto" v-if="isShowCode">{{
                  props.option[ctrlCode]
                }}</strong>
              </div>
            </template>
            <template #noResult>
              <strong>
                <div>
                  {{ resultEmpty }}
                </div>
                <br />
                <!-- to add country State/Province -->
                <div v-if="isAddCountry">
                  <CButton @click="emitAddCountry()">{{ buttonName }}</CButton>
                </div>
              </strong>
            </template>
            <template #noOptions>
              <strong>
                <div>
                  {{ optionEmpty }}
                </div>
                <br />
                <!-- to add country State/Province -->
                <div v-if="isAddCountry">
                  <CButton @click="emitAddCountry()">{{ buttonName }}</CButton>
                </div>
              </strong>
            </template>
          </multiselect>
          <!-- <ion-item v-else-if="isMobile">
        
          <ion-select interface="popover" @ionChange="onChange($event)" :multiple="false" >
            <ion-select-option v-for="(opt,dindex) in options" :key="'drop'+dindex" :value="opt.ctrlCode" >
              {{opt[ctrlName]}}
            </ion-select-option>
          </ion-select>
      </ion-item> -->
          <!-- {{ errors[0] }} -->
          <div
            v-if="errors && errors[0]"
            v-getError="emitError(id != null ? id : null)"
            class="invalid-feedback icon"
          >
            <div v-if="!selValueModel || selValueModel.length == 0">
              <span>{{ errors[0] }}</span>
            </div>
          </div>
        </div>

        <div v-if="noAgents" class="msg-no-data">
          {{ $t("cAlert.noAgent") }}
        </div>
        <div v-if="alertValue">
          <CAlert show color="danger">
            <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
              <p v-for="(value, index) in alertValue.valueList" :key="index">
                {{ value }}
              </p>
            </div>
            <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
              <span>{{ $t("cAlert.errorCode") + " : " }}</span
              ><span v-for="(code, index) in alertValue.codeList" :key="index">
                {{ code + " " }}
              </span>
            </div>

            <div v-if="alertValue && alertValue.constructor == String">
              <p>
                {{ alertValue }}
              </p>
            </div>
          </CAlert>
          <!-- <CAlert show color="danger">{{ alertValue }}</CAlert> -->
        </div>
      </CCol>
    </CRow>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import apiUserRoles from "../../services/apiUserRoles.js";

import { mapGetters, mapActions } from "vuex";
import {
  ALL_APPS_ID,
  ALL_APPS_NAME,
  COMPONENT_NAME,
  DEFAULT_DIY_TAG,
  NOTIFICATION_STATUS,
  DEFAULT_DROPDOWN_STATUS,ITEM_UNITS,ITEM_ECOM
} from "../../util/constants.js";
import BaseConfiguration from "../../config/base.js";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
  VALIDATE_GL_SUCCESS_RES,
} from "../../util/apiValidate.js";
import { handleJWT, getErrorAlertValue } from "../../util/util";
import SERVICE_URL from "../../config/API_DATA";
import Logger from "../../services/logger";
import { LOG_TYPE, FILE_NAME } from "../../config/API_DATA";
import ApiDIYGetDiyCategory from "../../services/diy/apiGetDiyCategories";
import ApiDIYGetDiyGroupLs from "../../services/diy/apiGetGroupLs";
import ApiDIYGetDiyFieldofWork from "../../services/diy/apiGetFieldofWork";
import apiGetDiyCategories from '../../services/diy/apiCreateCategory';
import ApiDIYGetCountryLs from '../../services/diy/apiGetCountryLs'
export default {
  name: "cDropDown",
  components: {
    ValidationProvider,
    Multiselect,
  },
  model: {
    prop: "selValue",
  },

  directives: {
    getError: {
      // directive definition
      inserted: function (el) {
        el.focus();
      },
    },
  },

  props: {
    isTaggable:{
      type: Boolean,
      default: false
    },
    toEmit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isManageUser: {
      type: Boolean,
      default: false,
    },

    selValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Array,
      default: null,
    },
    // isCancelled: {
    //   type: Boolean,
    //   default: false
    // },
    isAllowEmpty: {
      type: Boolean,
      default: false,
    },

    isShowCode: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    ctrlName: {
      type: String,
      default: "",
    },
    ctrlName1: {
      type: String,
      default: "",
    },
    ctrlCode: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    paxType: {
      type: String,
      default: null,
    },
    //to show error message for state list
    showError: {
      type: Boolean,
      default: false,
    },
    //get selected country code to get corresponding state list response
    cntryCode: {
      type: Object,
      default: undefined,
    },
    resultEmpty: {
      type: String,
      // default: 'No matching items found'
      default: function () {
        return this.$t("cAlert.resEmpty");
      },
    },
    optionEmpty: {
      type: String,
      default: function () {
        return this.$t("cAlert.optEmpty");
      },
    },
    buttonName: {
      type: String,
      default: function () {
        return this.$t("cAlert.buttonName");
      },
    },
    // to add country State/Province
    isAddCountry: {
      type: Boolean,
      default: false,
    },
    defaultCountryCode: {
      type: String,
      default: null,
    },
    defaultUserRole: {
      type: String,
      default: null,
    },
    groupId:{
      type: Object|Number,
      default: null,
    },
     isCategory: {
      type: Boolean,
      default: false,
    },
    isFieldOfWrk: {
      type: Boolean,
      default: false,
    },
    isItemName: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isMobile: BaseConfiguration.isMobile,
    options: [],
    selected: false,
    selValueModel: null,
    showAlert: false,
    noAgents: false,
    alertValue: null,
  }),

  async created() {
    if (this.toEmit) {
      this.$on("emitError", this.emitError /*the name of the method to call */);
    }
    
    this.selValueModel = this.selValue;
   
    await this.getDpOptions();
  },

  watch: {
    selValue(val) {
      if (val !== this.selValueModel) {
        this.selValueModel = val;
      }
    },
    // selValueModel(newVal, oldVal) {

    //   if (BaseConfiguration.isDebug) console.log("watch-selValueModel");
    //   if (oldVal == null)
    //   this.onChange(newVal);
    // }
  },
  computed: {
    ...mapGetters("language", {
      lang: "GET_LANGUAGE",
    }),
    ...mapGetters("sCountryList", {
      countryResponse: "GET_COUNTRY_RES",
      // countryCode: "GET_COUNTRY_CODE"
    }),
    ...mapGetters("sStateList", {
      stateResponse: "GET_STATE_RES",
    }),
    ...mapGetters("sUserRoles", {
      userRolesResponse: "GET_USER_ROLES_RES",
    }),
    ...mapGetters("sManageUsers", {
      GET_TAGS_LIST: "GET_TAGS_LIST",
    }),
     ...mapGetters("sManageDiyPost", {
      GET_PROJECT_ITEM_LS: "GET_PROJECT_ITEM_LS",
      GET_COUNTRY_LS: "GET_COUNTRY_LS"
    }),
  },

  methods: {
    ...mapActions("sUserRoles", ["SET_USER_ROLES_REQUEST"]),
    ...mapActions("sUserRoles", ["SET_USER_ROLES_RESPONSE"]),
    ...mapActions("sUserRoles", ["CLEAR_USER_ROLES_STORE"]),
    

    ...mapActions("sManageDiyPost", ["SET_COUNTRY_LS"]),

    emitError(pName) {
      this.$emit("caughtError", pName);
    },

    emitAddCountry() {
      this.$emit("addCountry");
    },
    getRolesRequest() {
      let retValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getRolesRequest");
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getRolesRequest",
          err.toString(),
          "getRolesRequest",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },
    async getDpOptions() {
      try {
        if (this.url) {
          switch (this.url) {
            case SERVICE_URL.GET_USER_ROLES:
              {
                let allRoles = await this.getUserRoles();

                if (allRoles != null) {
                  this.options = JSON.parse(JSON.stringify(allRoles));
                } else {
                  this.options = [];
                }

                if (this.isManageUser && this.options && this.options.length) {
                  this.options.unshift({
                    roleName: DEFAULT_DROPDOWN_STATUS.ALL,
                  });
                  this.selValueModel = {
                    roleName: DEFAULT_DROPDOWN_STATUS.ALL,
                  };
                }
                if (!(this.selValueModel && this.selValueModel.roleName)) {
                  this.selValueModel = this.getDefaultRole(this.options);
                }
                this.onChange(this.selValueModel);
              }
              break;

            case SERVICE_URL.GET_DIY_CATEGORY:
              {
                let lsStatus = await this.getDiyCategories(this.groupId);
                this.options = lsStatus;
                if (this.selValueModel&& this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.categoryid == this.selValueModel.categoryid
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

               case SERVICE_URL.GET_DIY_GROUP:
              {
                let lsStatus = await this.getDiyGroups();
                this.options = lsStatus;
                if (this.selValueModel) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.groupid == this.selValueModel
                  );
                }else{
                  this.selValueModel = this.getDefaultGroup(this.options);
                  
                }
                this.onChange(this.selValueModel);
              }
              break;

              

            case SERVICE_URL.GET_DIY_TAG:
              {
                let lsStatus = await this.getDiyFieldofWork(this.groupId);
                this.options = lsStatus;
                if (this.selValueModel && this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.field_of_work == this.selValueModel
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

              case SERVICE_URL.GET_DIY_TAGS_LIST:
              {
                let lsStatus = this.GET_TAGS_LIST;
                this.options = lsStatus;
                if (this.selValueModel && this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e == this.selValueModel
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

              case SERVICE_URL.GET_DIY_ITEM_LIST:
              {
                 let lsStatus = this.GET_PROJECT_ITEM_LS
                this.options = lsStatus;
                if (this.selValueModel && this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.item_name == this.selValueModel
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

               case SERVICE_URL.GET_DIY_UNIT_LIST:
              {
                let lsStatus = ITEM_UNITS.UNIT_LIST;
                this.options = lsStatus;
                if (this.selValueModel && this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.symbol == this.selValueModel
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

              case SERVICE_URL.GET_DIY_ECOM_SOURCE:
              {
                let lsStatus = ITEM_ECOM.SOURCE_LIST;
                this.options = lsStatus;
                if (this.selValueModel && this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.name == this.selValueModel
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

              case SERVICE_URL.GET_DIY_COUNTRY:
              {
                let lsStatus = this.GET_COUNTRY_LS;
                this.options = lsStatus;
                if (this.selValueModel && this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.id == this.selValueModel
                  );
                }
                this.onChange(this.selValueModel);
              }
              break;

             

            case SERVICE_URL.STATUS:
              {
                let lsStatus = [
                  {
                    name: DEFAULT_DROPDOWN_STATUS.ACTIVE,
                    value: true,
                  },
                  {
                    name: DEFAULT_DROPDOWN_STATUS.INACTIVE,
                    value: false,
                  },
                ];
                this.options = lsStatus;
              }
              break;
               case SERVICE_URL.NOTIFICATION_STATUS:
              {
                let lsStatus = [
                  {
                    name: NOTIFICATION_STATUS.ALL,
                    value: NOTIFICATION_STATUS.ALL,
                  },
                  {
                    name: NOTIFICATION_STATUS.DRAFT,
                    value: NOTIFICATION_STATUS.DRAFT,
                  },
                  {
                    name: NOTIFICATION_STATUS.PENDING,
                    value: NOTIFICATION_STATUS.PENDING,
                  },
                  {
                    name: NOTIFICATION_STATUS.PUBLISHED,
                    value: NOTIFICATION_STATUS.PUBLISHED,
                  },
                ];
                this.options = lsStatus;
              }
              break;
            case SERVICE_URL.SELECT_ORG:
              {
                let lsStatus = [
                  {
                    name: ALL_APPS_NAME.DIY,
                    value: ALL_APPS_ID.DIY,
                  },
                  {
                    name: ALL_APPS_NAME.PP,
                    value: ALL_APPS_ID.PP,
                  },
                ];
                this.options = lsStatus;
                if(this.selValueModel==null){
                this.selValueModel = lsStatus[0];
                }
                this.onChange(this.selValueModel);
              }
              break;

            default:
              throw "invalid service url -> " + this.url;
          }
        } else {
          throw "invalid service url -> " + this.url;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.cDropDown,
          "error in getDpOptions",
          err.toString(),
          "getDpOptions",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getDefaultGroup(pGroupList) {
      let returnValue;
      try {
         if (pGroupList && pGroupList.length > 0) {
          returnValue = pGroupList[0];
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.cDropDown,
          "error in getDefaultGroup options",
          err.toString(),
          "getDefaultGroup",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    getDefaultRole(pRolesList) {
      let returnValue;
      try {
        if (this.defaultUserRole) {
          for (let i = 0; i < pRolesList.length; i++) {
            if (pRolesList[i].roleName == this.defaultUserRole) {
              returnValue = pRolesList[i];
              break;
            }
          }
        } else if (pRolesList && pRolesList.length > 0) {
          returnValue = pRolesList[0];
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.cDropDown,
          "error in dropdown options",
          err.toString(),
          "getDefaultRole",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    async getUserRoles() {
      let returnValue;
      try {
        let userRolesReq = apiUserRoles.buildRequest(this.getRolesRequest());
        if (userRolesReq) {
          this.SET_USER_ROLES_REQUEST(userRolesReq);
          let userRolesRes = await apiUserRoles.getResponse(userRolesReq);
          if (
            VALIDATE_SUCCESS_RES(userRolesRes) &&
            userRolesRes.data.data.length > 0
          ) {
            //save res in store
            returnValue = userRolesRes.data.data;
            this.SET_USER_ROLES_RESPONSE(returnValue);
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getting response from Getting User Roles API",
          err.toString(),
          "getUserRoles",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

    async getDiyCountries() {
      let returnValue;
      try {
        let aiyCategoryReq = ApiDIYGetCountryLs.buildRequest();
        if (aiyCategoryReq) {
          let userRolesRes = await ApiDIYGetCountryLs.getResponse(
            aiyCategoryReq
          );
         
          if (VALIDATE_GL_SUCCESS_RES(userRolesRes)) {
            //save res in store
            returnValue = userRolesRes.data.diy_project_countries;
            this.SET_COUNTRY_LS(returnValue)
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getDiyCountries",
          err.toString(),
          "getDiyCountries",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    async getDiyFieldofWork(pGroupId) {
      let returnValue;
      try {
        let aiyCategoryReq = ApiDIYGetDiyFieldofWork.buildRequest(pGroupId);
        if (aiyCategoryReq) {
          let userRolesRes = await ApiDIYGetDiyFieldofWork.getResponse(
            aiyCategoryReq
          );
         
          if (VALIDATE_GL_SUCCESS_RES(userRolesRes)) {
            //save res in store
            returnValue = userRolesRes.data.diy_project_project;
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getting response from getDiyFieldofWork",
          err.toString(),
          "getDiyFieldofWork",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

    async getDiyGroups() {
      let returnValue;
      try {
        let aiyCategoryReq = ApiDIYGetDiyGroupLs.buildRequest();
        if (aiyCategoryReq) {
          let userRolesRes = await ApiDIYGetDiyGroupLs.getResponse(
            aiyCategoryReq
          );
         
          if (VALIDATE_GL_SUCCESS_RES(userRolesRes)) {
            //save res in store
            returnValue = userRolesRes.data.diy_project_project_group;
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getting response from getDiyGroups",
          err.toString(),
          "getDiyGroups",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

    async getDiyCategories(pGroupId) {
      let returnValue;
      try {
        let aiyCategoryReq = ApiDIYGetDiyCategory.buildRequest(pGroupId);
        if (aiyCategoryReq) {
          let userRolesRes = await ApiDIYGetDiyCategory.getResponse(
            aiyCategoryReq
          );
         
          if (VALIDATE_GL_SUCCESS_RES(userRolesRes)) {
            //save res in store
            returnValue = userRolesRes.data.diy_project_category;
          } else {
            let error = getErrorMessage(userRolesRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request"; //
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_DROPDOWN,
          "error in getting response from Getting User Roles API",
          err.toString(),
          "getDiyCategories",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

  

    getDefaultPaxTitle(pOptionsList) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log(" getDefaultPaxTitle");
        if (pOptionsList != null && pOptionsList.length > 0) {
          retValue = pOptionsList[0];
        } else {
          throw "invalid options list";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.C_DROPDOWN,
          "error in getDefaultPaxTitle",
          err.toString(),
          "getDefaultPaxTitle",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getDefaultCountry(pOptionsList) {
      let retValue;
      try {
        if (pOptionsList != null && pOptionsList.length > 0) {
          for (let i = 0; i < pOptionsList.length; i++) {
            if (
              pOptionsList[i].countrycode ==
              (this.defaultCountryCode
                ? this.defaultCountryCode
                : BaseConfiguration.defaultCountryValue)
            ) {
              retValue = pOptionsList[i];
              break;
            }
          }
        } else {
          throw "invalid options list";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.cDropDown,
          "error in dropdown options",
          err.toString(),
          "getDefaultCountry",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getDefaultPaySchedule(pOptionList) {
      let retValue;
      try {
        if (pOptionList != null && pOptionList.length > 0) {
          // for (let i = 0; i < pOptionList.length; i++) {
          //   if (pOptionList[i].scheduleid == 1) {
          retValue = pOptionList[0];
          //     break;
          //   }
          // }
        } else {
          throw "invalid schedule list";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.cDropDown,
          "error in dropdown options",
          err.toString(),
          "getDefaultSchedule",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    isInValidOptions() {
      let retValue = false;
      if (this.options) {
        //check options length
        retValue = false;
      } else {
        retValue = true;
      }
      return retValue;
    },

     async createCategory(pCategory,groupId) {
      let returnValue;

      try {
        let req = {
          categoryname: pCategory,
          groupid: groupId
        }
       
        let createUserRequest = apiGetDiyCategories.buildRequest(req);

        if (createUserRequest != null) {

          let createUserResponse = await apiGetDiyCategories.getResponse(
            createUserRequest
          );
          if (VALIDATE_GL_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  createCategory",
          err.toString(),
          "createCategory()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    async addTag (newTag) {
      if(this.isCategory) {
        let createCategory = await this.createCategory(newTag,this.groupId);
        if(createCategory) {
        let lsStatus = await this.getDiyCategories(this.groupId);
                this.options = lsStatus;
                if (this.selValueModel&& this.options && this.options.length!=0) {
                  this.selValueModel = lsStatus.find(
                    (e) => e.categoryname == newTag
                  );
                }
                
                this.onChange(this.selValueModel);
        }
      }else if(this.isFieldOfWrk){
        let opt = {
          field_of_work : newTag
        }
      this.options.push(opt)
       this.selValueModel=opt
        this.onChange(this.selValueModel)
      } else if(this.isItemName){
         let opt = {
        "itemid": null,
        "item_name": newTag,
        "projectid": null,
        "qty": null,
        "unit": null,
        "project_item_links": [
          {
            "ecom_link": null,
            "ecom_source": null,
            "itemid": null,
            "linkid":null,
            "countryid": null
          }
        ]
      }
      this.options.push(opt)
       this.selValueModel=opt
       this.onChange(this.selValueModel)
      }else{
        if(this.isMultiple){
      this.options.push(newTag)
       this.selValueModel.push(newTag)
        this.onChange(this.selValueModel)
        }else{
           this.options.push(newTag)
           this.selValueModel=newTag
            this.onChange(this.selValueModel)
        }
      
      }
      
    },

    onChange(newVal) {
      // if(BaseConfiguration.isMobile && newVal.target){
      //   let value = newVal.target.value;
      //   for (let i = 0; i < this.options.length; i++) {
      //    if(Object.values(this.options[i]).includes(value.trim())){
      //     newVal=this.options[i];
      //    }
      //   }
      // }
      
      this.$emit("input", newVal);
     
    },
  },
};
</script>

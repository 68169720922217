import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import gql from "graphql-tag";
import Apollo from "../../graphql/ApolloClient";

export default class ApiDIYGetCountryLs {
    static buildRequest() {
        let returnValue = null;
        try {
            let query = gql`query 
            {   diy_project_countries {
                calling_code
                id
                value
              }
            }
              `;

            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_COUNTRY_LS, "error in building body for GetAllUser api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloQueryRequest(SERVICE_URL.GET_COUNTRY_LIST, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_COUNTRY_LS, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}